import styled, { css } from 'styled-components';
import { Grid } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';
import { xsBodyBook } from '../../atoms/Typography/index.styled';

const USPPrimary = styled.section`
	margin-top: ${spacing[48]};
	margin-bottom: ${spacing[48]};

	${above(breakpoints[960], () => css`
		margin-top: ${spacing[64]};
		margin-bottom: ${spacing[64]};
	`)}

	${above(breakpoints[1440], () => css`
		margin-top: ${spacing[80]};
		margin-bottom: ${spacing[80]};
	`)}
`;

const USPSecondary = styled.section`
	padding-top: ${spacing[48]};
	padding-bottom: ${spacing[48]};
	background-color: var(--secondaryBackground);

	${above(breakpoints[960], () => css`
		padding-top: ${spacing[64]};
		padding-bottom: ${spacing[64]};
	`)}

	${above(breakpoints[1440], () => css`
		padding-top: ${spacing[80]};
		padding-bottom: ${spacing[80]};
	`)}
`;

const USP__List = styled(Grid)`
	grid-row-gap: ${gap[32]};
	align-items: start;
	list-style-type: none;

	${above(breakpoints[960], () => css`
		grid-row-gap: ${gap[48]};
	`)}

	${above(breakpoints[1920], () => css`
		grid-row-gap: ${gap[64]};
	`)}
`;

const USP__Footnote = styled(Grid)``;

const USP__FootnoteText = styled(xsBodyBook)`
	grid-column: 1 / -1;
	margin-top: ${gap[24]};

	${above(breakpoints[600], () => css`
		text-align: center;
		margin-top: ${gap[48]};
	`)}

	${above(breakpoints[960], () => css`
		margin-top: ${gap[64]};
	`)}
`;

export default {
	USPPrimary,
	USPSecondary,
	USP__List,
	USP__Footnote,
	USP__FootnoteText
};
