import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import SVG from '../SVG';
import Styled from './index.styled';

const USPItem = ({ text, icon, rowItemsDesktopTabletMobile }) => {
	const theme = useContext(ThemeContext);
	const fill = theme.primaryForeground;

	return (
		<Styled.USPItem rowItemsDesktopTabletMobile={rowItemsDesktopTabletMobile}>
			<SVG name={icon} fill={fill} />
			<Styled.USPItem__text  as="p">{text}</Styled.USPItem__text>
		</Styled.USPItem>
	);
};

export default USPItem;
