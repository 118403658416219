import React from 'react';
import USPItem from '../../atoms/USPItem';
import Styled from './index.styled';

const getUSPStyle = type => {
	switch (type) {
		case 'primary':
			return Styled.USPPrimary;
		case 'secondary':
			return Styled.USPSecondary;
		default:
			return Styled.USPPrimary;
	}
};

type Item = {
	id: string
	text: string
	icon: string
};

type Config = {
	items: Array<Item>
	rowItemsDesktopTabletMobile: string
	footnote: string
	uspType: string
	anchorId?: string
};

const USP = ({
	items,
	rowItemsDesktopTabletMobile = '4,4,1',
	footnote,
	uspType = 'primary',
	anchorId,
}: Config) => {
	const USPStyle = getUSPStyle(uspType);

	return (
		<USPStyle id={anchorId || ''}>
			<Styled.USP__List as="ul">
				{items.map(item => (
					<USPItem
						{...item}
						key={item.id}
						rowItemsDesktopTabletMobile={rowItemsDesktopTabletMobile}
					/>
				))}
			</Styled.USP__List>
			{footnote && (
				<Styled.USP__Footnote>
					<Styled.USP__FootnoteText>{footnote}</Styled.USP__FootnoteText>
				</Styled.USP__Footnote>
			)}
		</USPStyle>
	);
};

export default USP;
