import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';
import { smBodyBook } from '../Typography/index.styled';

const USPItem = styled.li`
	display: flex;
	grid-column-end: span 4;
	align-items: flex-start;

	svg {
		width: 32px;
		flex-basis: 32px;
		margin-right: ${gap[16]};
		fill: var(--primaryForeground);
	}

	${above(breakpoints[600], () => css`
		grid-column-end: span ${props => (8 / props.rowItemsDesktopTabletMobile.split(',')[1])};
		flex-direction: column;

		svg {
			width: 64px;
			flex-basis: 64px;
			margin-right: auto;
			margin-bottom: ${gap[16]};
			margin-left: auto;
			padding: ${gap[8]};
		}
	`)}

	${above(breakpoints[960], () => css`
		grid-column-end: span ${props => (12 / props.rowItemsDesktopTabletMobile.split(',')[0])};
	`)}

	${above(breakpoints[1440], () => css`
		svg {
			width: 80px;
			flex-basis: 80px;
			margin-bottom: ${gap[24]};
		}
	`)}
`;

const USPItem__text = styled(smBodyBook)`
	flex: 0 0 calc(100% - ${gap[48]});

	${above(breakpoints[600], () => css`
		text-align: center;
		align-self: center;
	`)}
`;

export default {
	USPItem,
	USPItem__text,
};
